import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginPatil.css';
import 'font-awesome/css/font-awesome.min.css'; // This imports Font Awesome CSS
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import './Login.css';
import { useVendorContext } from './VendorContext';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import Captcha from './Captcha';

function LoginPatil({ onSuccessfulLogin }) {
  const navigate = useNavigate();
  const { setVendorNumber } = useVendorContext();

  // const handleLogoClick = () => {
  //   window.location.href = "http://www.patilgroup.com/";
  // };

  const [formData, setFormData] = useState({
    userId: '',
    password: '',
    captcha: '',
  });

  // const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const initializeCaptcha = (ctx) => {
    // setLoading(true);
    setUserInput('');
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    setTimeout(() => {
      setLoading(false);
    }, 10);
    drawCaptchaOnCanvas(ctx, newCaptcha);
    // await new Promise(resolve => setTimeout(resolve, 10));
    // setLoading(false);

  };

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext('2d');
  //   initializeCaptcha(ctx);
  // });

  const generateCaptchaText = () => {
    let captcha = '';
    for (let i = 0; i < 2; i++) {
      captcha += generateRandomChar(65, 90);
      captcha += generateRandomChar(97, 122);
      captcha += generateRandomChar(48, 57);
    }
    return captcha.split('').sort(
      () => Math.random() - 0.5).join('');
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    initializeCaptcha(ctx);
  }, []);

  const generateRandomChar = (min, max) =>
    String.fromCharCode(Math.floor
      (Math.random() * (max - min + 1) + min));

  const drawCaptchaOnCanvas = (ctx, captcha) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)'];
    const letterSpace = 150 / captcha.length;
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 25;
      ctx.font = '20px Roboto Mono';
      ctx.fillStyle = textColors[Math.floor(
        Math.random() * 2)];
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,

        // Randomize Y position slightly 
        Math.floor(Math.random() * 16 + 25),
        100
      );
    }
  };

  const handleUserInputChange = (e) => {
    // setUserInput(e.target.value);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleCaptchaSubmit = () => {
  //   console.log('entering handleCaptchaSubmit');
  //   console.log('errorMessages.recaptcha:', errorMessages.recaptcha);
  //   if (userInput === captchaText) {
  //     setErrorMessages({
  //       ...errorMessages,
  //       recaptcha: '123',
  //       userNotRegistered: '123',
  //       otherErrors: '123',
  //     });

  //     alert('Success');
  //   } 

  //   else {
  //     setErrorMessages({
  //       ...errorMessages,
  //       recaptcha: 'Please Enter Correct Captcha',
  //       userNotRegistered: '1234',
  //       otherErrors: '1235',
  //     });

  //     // alert('Incorrect Captcha Entered. Please provide Correct Captcha.');
  //     const canvas = canvasRef.current;
  //     const ctx = canvas.getContext('2d');
  //     initializeCaptcha(ctx);
  //   }
  //   console.log('exiting handleCaptchaSubmit');
  // };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      //navigate('/registration3');
      navigate(-1);
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // This callback will be called when reCAPTCHA is successfully verified
  // const handleRecaptchaVerify = (response) => {
  //   setRecaptchaVerified(true);
  //   setErrorMessages({
  //     ...errorMessages,
  //     recaptcha: '',
  //   });
  // };

  var [errorMessages, setErrorMessages] = useState({
    recaptcha: '',
    userNotRegistered: '',
    password: '',
    networkError: '',
    otherErrors: '',
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("recaptchaVerified is :", recaptchaVerified);
    console.log("Before errors: ", errorMessages);
    // setErrorMessages({
    //   ...errorMessages,
    //   recaptcha: 'a',
    //   userNotRegistered: 'b',
    //   otherErrors: 'c',
    // });
    console.log("After errors: ", errorMessages);
    // reCAPTCHA is not verified, set the recaptcha error message
    // if (!recaptchaVerified) {
    //   setErrorMessages({
    //     ...errorMessages,
    //     recaptcha: 'Please verify that you are not a Robot.',
    //   });
    //   return;
    // }

    // else if (recaptchaVerified) {
    //   setErrorMessages({
    //     ...errorMessages,
    //     recaptcha: '',
    //   });
    // }

    /*else if(errorMessages.otherErrors!='') {
      setErrorMessages({
        ...errorMessages,
        otherErrors: '',
      })
    }*/

    console.log('User ID is:', formData.userId);
    console.log('Password:', formData.password);

    const username = 'SRIAABAP';
    const password = 'Patil@2234';
    // const credentials = `${username}:${password}`;
    // const base64Credentials = btoa(credentials);
    const basicAuth = 'Basic ' + btoa(username + ':' + password);
    let apiUrl = ``;
    if (formData.userId.includes("@")) {
      apiUrl = `http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=200&VENDOR_P_EMAIL=${formData.userId}&AUTO_PASSWORD=${formData.password}`;
    }
    else {
      apiUrl = `http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&AUTO_USER=${formData.userId}`
    }
    //const vendorNumber = parseInt(formData.userId);
    // ----const apiUrl = `http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&AUTO_USER=${formData.userId}&AUTO_PASSWORD=${formData.password}`
    //const apiUrl=`http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&AUTO_USER=${formData.userId}&AUTO_PASSWORD=${formData.password}`
    //const apiUrl=`http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&VENDOR_NO=${formData.userId}&AUTO_PASSWORD=${formData.password}`
    // const apiUrl = `http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=200&VENDOR_P_EMAIL=${formData.userId}&AUTO_PASSWORD=${formData.password}`;
    console.log(apiUrl);
    console.log("Form Data is:", formData);
    //mmm
    // setErrorMessages({
    //   ...errorMessages,
    //   recaptcha: '',
    //   userNotRegistered: '',
    //   otherErrors: '',
    // });


    // Check the API response and handle accordingly
    console.log("UserId is", formData.userId);
    if (!formData.userId.trim()) {
      setErrorMessages((prevErrorMessages) => ({
        ...prevErrorMessages,
        recaptcha: '',
        password: '',
        networkError: '',
        userNotRegistered: 'Please Enter UserId / Email Address',
        otherErrors: '',
      }));
    }

    else if (!formData.password.trim()) {
      setErrorMessages((prevErrorMessages) => ({
        ...prevErrorMessages,
        recaptcha: '',
        networkError: '',
        password: 'Please Enter Password',
        userNotRegistered: '',
        otherErrors: '',
      }));
    }

    else if (!formData.captcha.trim()) {
      setErrorMessages((prevErrorMessages) => ({
        ...prevErrorMessages,
        recaptcha: 'Please Enter Captcha',
        networkError: '',
        password: '',
        userNotRegistered: '',
        otherErrors: '',
      }));
    }

    else {
      try {
        // const username = 'SRIAABAP';
        // const password = 'Patil@2234';
        // const credentials = `${username}:${password}`;
        // const base64Credentials = btoa(credentials);
        console.log("Entered TRY Block");
        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          recaptcha: '',
          password: '',
          networkError: '',
          userNotRegistered: '',
          otherErrors: '',
        }));
        setLoading(true);
        console.log("Spin");
        const response = await axios.get(
          apiUrl,
          {
            headers: {
              'Authorization':  basicAuth,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': true,
              'Access-Control-Allow-Methods': '*',
              'Access-Control-Allow-Headers': '*',
            }
          }
         );

        // console.log(response.status);

        // const response = await fetch(apiUrl, {
        //   method: 'GET',
        //   headers: {
        //     'Authorization': 'Basic ' + base64Credentials,
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Credentials': true,
        //     // Other headers if needed
        //   },
        //   // mode: 'no-cors', // Note: 'no-cors' mode will prevent access to the response content. Uncomment only if you really need it.
        // });
        console.log("Hi");
        // Print the HTTP status code
        console.log('API Status Code:', response.status);
        console.log(response);
        console.log("API response is:", response.data);
        //console.log('API Status Code:', response.then);
        // response.then((aa) =>{
        //   console.log(aa);
        // });
        // Check the API response and handle accordingly
        if (response.status === 200) {
          const apiData = await response.data; // Assuming the API returns data with vendorNo
          console.log("password is", formData.password, "type", typeof (formData.password));
          console.log("apiData is:", apiData);
          console.log("api password is", apiData.AUTO_PASSWORD, "type", typeof (apiData.AUTO_PASSWORD));
          setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            networkError: '',
          }));
          // if (!Object.values(errorMessages).some(message => message)) {
          //   setErrorMessages({
          //     ...errorMessages,
          //     recaptcha: '',
          //     password: '',
          //     userNotRegistered: '',
          //     otherErrors: '',
          //   });
          if (apiData.AUTO_USER && apiData.AUTO_PASSWORD === formData.password) {
            const autoUserFromApi = apiData.AUTO_USER;
            const vendorStatusFromAPI = apiData.VEND_STATUS;
            console.log("Api Data's vendor Number is in Login Patil:", autoUserFromApi);
            setVendorNumber(apiData.AUTO_USER, apiData.VEND_STATUS);
            console.log("vendor status is : ", apiData.VEND_STATUS);
            localStorage.setItem('token', 'your_token_value');
            if (formData.captcha === captchaText) {
              setErrorMessages((prevErrorMessages) => ({
                ...prevErrorMessages,
                recaptcha: '',
                networkError: '',
                password: '',
                userNotRegistered: '',
                otherErrors: '',
              }));

              // alert('Success');
              onSuccessfulLogin(autoUserFromApi, vendorStatusFromAPI);
            }

            else {
              setErrorMessages((prevErrorMessages) => ({
                ...prevErrorMessages,
                recaptcha: 'Please Enter Correct Captcha',
                userNotRegistered: '',
                password: '',
                otherErrors: '',
                networkError: '',
              }));

              // alert('Incorrect Captcha Entered. Please provide Correct Captcha.');
              const canvas = canvasRef.current;
              const ctx = canvas.getContext('2d');
              initializeCaptcha(ctx);
            }

          }

          else if (!apiData.VENDOR_NO) {
            // Handle the case where the vendorNo is not present in the API response
            setErrorMessages((prevErrorMessages) => ({
              ...prevErrorMessages,
              recaptcha: '',
              password: '',
              userNotRegistered: 'User Not Found',
              otherErrors: '',
              networkError: '',
            }));
          }

          else if (apiData.AUTO_PASSWORD !== formData.password) {
            setErrorMessages((prevErrorMessages) => ({
              ...errorMessages,
              recaptcha: '',
              password: 'Password is Incorrect, Please Check',
              userNotRegistered: '',
              otherErrors: '',
              networkError: '',
            }));
          }

          /*else {
            // Handle the case where the vendorNo is not present in the API response
            setErrorMessages({
              ...errorMessages,
              otherErrors: 'VendorNo not found in API response and password is wrong',
            });
          }*/
          else {
            // Handle authentication failure
            alert('Authentication failed');
          }
          // }

          // else {
          //   // errorMessages is not empty, handle the errors
          //   console.error('Error Messages:', errorMessages);

          //   // Optionally, you can show the error messages to the user or update the UI state
          //   // setErrorMessages({
          //   //   recaptcha: 'Please Enter Correct Captcha',
          //   //   // Add other error messages if needed
          //   // });
          //   alert('There are errors in the form. Please fix them before proceeding.');
          // }
        }

        else if (response.status === 401) {
          setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            recaptcha: '',
            networkError: '',
            password: '',
            userNotRegistered: '',
            otherErrors: 'Error while Authenticating',
          }));
        }
      }
      catch (error) {
        console.log('API request error:', error.message);
        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          networkError: 'Network Issue',
        }));
      }
      finally {
        setLoading(false);
      }
      // try {
      //   // const username = 'SRIAABAP';
      //   // const password = 'Patil@2234';
      //   // const credentials = `${username}:${password}`;
      //   // const base64Credentials = btoa(credentials);
      //   console.log("Entered TRY Block");
      //   setErrorMessages((prevErrorMessages) => ({
      //     ...prevErrorMessages,
      //     recaptcha: '',
      //     password: '',
      //     networkError: '',
      //     userNotRegistered: '',
      //     otherErrors: '',
      //   }));
      //   setLoading(true);
      //   console.log("Spin");
      //   const response = await axios.get(apiUrl, {
      //     headers: {
      //       'Authorization': 'Basic ' + base64Credentials,
      //       'Access-Control-Allow-Origin': '*',
      //       // Other headers if needed
      //     },
      //     // Axios doesn't support 'no-cors' mode
      //     // You might need to handle CORS issues differently
      //     // mode: 'no-cors', 
      //   });

      //   console.log("Hi");
      //   // Print the HTTP status code
      //   console.log('API Status Code:', response.status);

      //   // Check the API response and handle accordingly
      //   if (response.status === 200) {
      //     const apiData = response.data; // Assuming the API returns data with vendorNo
      //     console.log("password is", formData.password, "type", typeof (formData.password));
      //     console.log("apiData is:", apiData);
      //     console.log("api password is", apiData.AUTO_PASSWORD, "type", typeof (apiData.AUTO_PASSWORD));
      //     setErrorMessages((prevErrorMessages) => ({
      //       ...prevErrorMessages,
      //       networkError: '',
      //     }));
      //     // Rest of your code logic...
      //   } else if (response.status === 401) {
      //     setErrorMessages((prevErrorMessages) => ({
      //       ...prevErrorMessages,
      //       recaptcha: '',
      //       networkError: '',
      //       password: '',
      //       userNotRegistered: '',
      //       otherErrors: 'Error while Authenticating',
      //     }));
      //   }
      // } catch (error) {
      //   console.log('API request error:', error.message);
      //   setErrorMessages((prevErrorMessages) => ({
      //     ...prevErrorMessages,
      //     networkError: 'Network Issue, Please Connect to VPN',
      //   }));
      // } finally {
      //   setLoading(false);
      // }
    }
  };

  return (
    <div>
      {/* {loading ? (<div className='spinner-container'>
        <div className="loading-spinner"></div>
        <span className='loading-message'>Loading...</span>
      </div>) } */}
      {loading && (
        <div className='overlay'>
          <div className='spinner-container'>
            <div className="loading-spinner"></div>
            <span className='loading-message'>Loading...</span>
          </div>
        </div>
      )}
      <div className={`container-fluid bg-login ${loading ? 'blur' : ''}`} id="mainScreen">
        <div className='row bg-row d-flex justify-content-center align-items-center vh-100'>
          <div className="login-patil-container">
            <div className="login-card-header">
              <h5>Vendor Onboarding</h5>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="form-align">
                <div className="form-group">
                  <label htmlFor="userId" className="login-left-align-label mb-2">
                    User ID/ Email ID
                  </label>
                  <input
                    type="text"
                    className="form-control login-input-field"
                    id="userId"
                    name="userId"
                    placeholder="Enter User ID"
                    value={formData.userId}
                    onChange={handleInputChange}
                    maxLength="50"
                    autoComplete='user-name'
                  />
                  {errorMessages.userNotRegistered && <p className="error-message">{errorMessages.userNotRegistered}</p>}
                </div>

                <div className="form-group">
                  <label htmlFor="password" className="login-left-align-label mt-2 mb-2">
                    Password
                  </label>
                  {/* <input
                      type="password"
                      className="form-control login-input-field"
                      id="password"
                      name="password"
                      placeholder="Enter Password"
                      value={formData.password}
                      onChange={handleInputChange}
                      minLength="8"
                      maxLength="50"
                      autoComplete='current-password'
                    /> */}
                  <div className="password-input-container">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control login-input-field"
                      id="password"
                      name="password"
                      placeholder="Enter Password"
                      value={formData.password}
                      onChange={handleInputChange}
                      minLength="8"
                      maxLength="50"
                      autoComplete='current-password'
                    />
                    <span
                      className={`password-toggle-icon ${showPassword ? 'visible' : 'hidden'}`}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {/* {showPassword ? 'Hide' : 'Show'} */}
                      {/* <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /> */}
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </span>
                  </div>
                </div>
                {errorMessages.password && <p className="error-message">{errorMessages.password}</p>}
              </div>

              {/* Center the ReCAPTCHA widget */}
              <div className="mt-3">
                <div className="recaptcha-container mt-5">
                  {/* <ReCAPTCHA
                    sitekey="6LdzylcpAAAAAEUk_33yLn0kC68MflX8HFKZ6Wwr"
                    onChange={handleRecaptchaVerify}
                  /> */}
                  {/* <Captcha /> */}
                  <div>
                    <div className="wrapper">
                      <canvas ref={canvasRef}
                        width="200"
                        height="50"
                      >

                      </canvas>
                      {/* <button id="reload-button" onClick={
                        () => initializeCaptcha(
                          canvasRef.current.getContext('2d'))}>
                        Reload
                      </button> */}
                      <button id="reload-button" type="button" onClick={
                        () => initializeCaptcha(
                          canvasRef.current.getContext('2d'))} className="reload-button">
                        Reload
                      </button>
                    </div>
                    <div className='wrapper'>
                      <input
                        type="text"
                        id="user-input"
                        name="captcha"
                        placeholder="Enter the Captcha text"
                        value={formData.captcha}
                        onChange={handleUserInputChange} />

                      {/* <button id="submit-button"
                        onClick={handleCaptchaSubmit}>
                        Check
                      </button> */}
                    </div>
                    {errorMessages.recaptcha && <p className="error-message">{errorMessages.recaptcha}</p>}

                  </div>

                </div>
                {/*{errorMessages.recaptcha && <p className="error-message">{errorMessages.recaptcha}</p>} */}
              </div>

              <button type="submit" className="btn btn-primary btn-block mt-5 relativeness" disabled={loading}>
                Login
              </button>

              {errorMessages.networkError && <p className="error-message">{errorMessages.networkError}</p>}
              {errorMessages.otherErrors && <p className="error-message">{errorMessages.otherErrors}</p>}

              <div className="mt-2">
                <Link to="/forgot-password" target="_self">
                  {/*<a href="#forgot-password" onClick={handleForgetPasswordClick}>*/}
                  Forgot Password
                </Link>
              </div>
            </form>

            <div className='container-fluid mt-3'>
              <div className='row bg-row'>
                <div className="d-flex justify-content-center login-patil-icons">
                  <span className='patil-footer-description'>Connect with Us On : </span>
                  <div className="icon-space">
                    <a href="https://www.facebook.com/patilgroup/" target="_self">
                      <i className="fa fa-2x fa-brands fa-facebook login-facebook-icon"></i>
                    </a>
                  </div>
                  <div className="icon-space">
                    <a href="https://www.linkedin.com/company/patil-group/?originalSubdomain=in" target="_self" rel="noopener noreferrer">
                      <i className="fa fa-2x fa-brands fa-linkedin login-linkedin-icon" aria-hidden="true"></i>
                    </a>
                  </div>

                  {/* <div className="icon-space login-arrow-position" onClick={scrollToTop}>
                    <i className="fa-solid fa-word
                    
                    rcle-arrow-up login-arrow-icon"></i>
                  </div> */}
                  {/* <div className="icon-space">
              <a href="https://www.bing.com/search?q=patil+rail+infrastructure+pvt+ltd&qs=n&form=GEOTRI&sp=-1&ghc=2&pq=patil+rail&sc=7-10&sk=&cvid=BE2EC69A219A4D04BAF2379DEAD0B074&isRef=1&showTw=1&isAutoP=1%3E">
                <i className="fa fa-brands fa-2x fa-google login-google-icon g"></i>
              </a>
            </div>
            <div className="icon-space">
              <a href="http://www.patilgroup.com/contactus.html">
                <i className="fa fa-2x fa-solid fa-phone login-phone-icon"></i>
              </a>
            </div> */}
                </div>
              </div>
            </div>

          </div>
          {/*{showForgotPassword && <ForgotPassword />}*/}

        </div>
      </div>

    </div>
  );
}

export default LoginPatil;